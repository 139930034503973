//React dependicies
import React from "react";

//Import the component styling
import "../css/components/header.scss";

//Render the default
export default function Header() {
  return (
    <header>
      <nav className="main"></nav>
      <nav className="external">
        <a href="https://www.linkedin.com/in/keesvdvliet/" target="_blank">
          <i className="fab fa-linkedin"></i>
        </a>
      </nav>
    </header>
  );
}
