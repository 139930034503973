//React dependicies
import React from "react";

//Import the component styling
import "../css/components/hero.scss";

//Import subcomponents
import Skills from "./Skills";
import Header from "./Header";

//Import functions
import { fetchPageFromApi } from "../functions/fetchPageFromApi";
const pageID = 61; //ID of the home page in the CMS
const pageContent = await fetchPageFromApi(pageID);

//Set the title object
const mainTitle = pageContent.acf.title.split("|");

//Render the default
export default function Hero() {
  return (
    <section className="hero">
      <Intro />
      <Skills />
    </section>
  );
}

//Introduction components
function Intro() {
  return (
    <section className="intro">
      <Header />

      <h1 className="intro_title">
        {/* Split up the title into seperate lines and letter objects */}
        {mainTitle.map((line, i) => (
          <span className="line" key={i}>
            {line.split("").map((letter, j) => (
              //Create the single letters and add the correct animtion delay
              <span
                className="letter"
                style={{
                  animationDelay:
                    Number(Number(j * 0.025) + 0.15 + Number(i * 0.05) + 0.2) +
                    "s",
                }}
                key={j}
              >
                {letter}
              </span>
            ))}
          </span>
        ))}
      </h1>

      <div className="intro_text">
        <p>{pageContent.acf.text}</p>
      </div>
    </section>
  );
}
